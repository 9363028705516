export const youtubeAds = {
  id: 'youtube-ads',
  value: [16, 9],
  label: 'YouTube Ads'
};
export const linkedinAds = {
  id: 'linkedin-ads',
  value: [4, 3],
  label: 'LinkedIn ads'
};
export const tiktok = {
  id: 'tiktok-ads',
  value: [9, 16],
  label: 'TikTok'
};
export const instagramPosts = {
  id: 'instagram-posts',
  value: [1, 1],
  label: 'Instagram Posts'
};
export const standard = {
  id: 'standard',
  value: [3, 4]
};