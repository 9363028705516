import flatten from 'lodash/flatten';
export const EMPTY_RESOLUTION = {
  id: 'empty',
  value: [0, 0],
  label: ''
}; // Find resolution most similar to the
// device's current viewport width

export function discoverClosestResolution(previewGroups, maxDisoverWidth, fallback) {
  let targetWidth = 0;

  if (true) {
    targetWidth = window.innerWidth || 0;
  }

  if (typeof document !== 'undefined' && document.documentElement) {
    targetWidth = document.documentElement.clientWidth || 0;
  }

  const allResolutions = flatten(previewGroups.map(_ref => {
    let {
      items
    } = _ref;
    return items;
  }));
  const [smallestWidth] = allResolutions.map(_ref2 => {
    let {
      value
    } = _ref2;
    return value[0];
  }).sort((a, b) => a - b);
  targetWidth = Math.min(targetWidth, Math.max(smallestWidth, maxDisoverWidth)); // Find largest device, less than target width

  const discovered = allResolutions.reduce((acc, resolution) => {
    const [resolutionWidth] = resolution.value;

    if (resolutionWidth <= targetWidth && resolutionWidth > acc.value[0]) {
      return resolution;
    }

    return acc;
  }, EMPTY_RESOLUTION); // Return discovered or fallback

  return discovered.value[0] ? discovered : fallback;
} // Lookup a group by its'
// resolution item

export function findResolutionsGroup(previewGroups, resolution) {
  return previewGroups.find(group => group.items.some(_ref3 => {
    let {
      id
    } = _ref3;
    return id === resolution.id;
  }));
}
export function getProportionalDimensions(width, height, aspectRatio) {
  const currentAspectRatio = aspectRatio[0] / aspectRatio[1];
  const currentRatio = width / height;

  if (currentRatio > currentAspectRatio) {
    return [Math.ceil(height * currentAspectRatio), height];
  }

  return [width, Math.ceil(width / currentAspectRatio)];
}