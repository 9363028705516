import { useState, useEffect, useCallback } from 'react';
import { EMPTY_RESOLUTION, discoverClosestResolution, findResolutionsGroup, getProportionalDimensions } from './utils';
const PREFIX = 'components: WidgetPreviewContainer: useWidgetPreviewResolution:'; // Sync with ./settings.module.scss

const CONTAINER_PADDING = 84;
const CONTAINER_MIN_SIZE = 400;
const DEFAULT_ASPECT_RATIO = [1, 1];
export default function useWidgetPreviewResolution(_ref) {
  let {
    previewGroups,
    containerRef,
    initialResolutionStrategy = 'detect',
    defaultPreviewGroup = null,
    defaultPreviewResolution,
    defaultZoomLevel = 100,
    defaultAspectRatio = DEFAULT_ASPECT_RATIO,
    containerMinSize = CONTAINER_MIN_SIZE,
    onPreviewResolutionChange
  } = _ref;
  const {
    0: aspectRatio,
    1: setAspectRatio
  } = useState(defaultAspectRatio);
  const {
    0: activePreviewGroup,
    1: setActivePreviewGroup
  } = useState(defaultPreviewGroup || null);
  const {
    0: previewResolution,
    1: setResolution
  } = useState(initialResolutionStrategy === 'custom' ? defaultPreviewResolution : EMPTY_RESOLUTION);
  const {
    0: zoomLevel,
    1: setZoomLevel
  } = useState(defaultZoomLevel); // Detect optimial initial resolution
  // when inital strategy is detection

  useEffect(() => {
    if (initialResolutionStrategy !== 'detect') return;
    if (containerRef.current instanceof HTMLElement === false) return;
    const maxDisoverWidth = Math.round(containerRef.current.getBoundingClientRect().width);
    const updatedResolution = discoverClosestResolution(previewGroups, maxDisoverWidth, defaultPreviewResolution);
    setResolution(updatedResolution);
    setActivePreviewGroup(findResolutionsGroup(previewGroups, updatedResolution) || defaultPreviewGroup); // Broadcast updated resolution

    if (updatedResolution && onPreviewResolutionChange) {
      onPreviewResolutionChange(updatedResolution);
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [initialResolutionStrategy, containerRef.current]); // Use cover initial resolution to apply
  // a custom layout that fills the layout

  useEffect(() => {
    if (initialResolutionStrategy !== 'cover') return;
    if (containerRef.current instanceof HTMLElement === false) return;
    const {
      width,
      height
    } = containerRef.current.getBoundingClientRect();
    const updatedResolution = {
      id: 'custom',
      label: 'Custom',
      value: getProportionalDimensions(Math.max(width - CONTAINER_PADDING, containerMinSize), Math.max(height - CONTAINER_PADDING, containerMinSize), aspectRatio)
    };
    setResolution(updatedResolution); // Broadcast updated resolution

    if (onPreviewResolutionChange) {
      onPreviewResolutionChange(updatedResolution);
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [initialResolutionStrategy, aspectRatio, containerMinSize, containerRef.current]);
  const updatePreviewResolution = useCallback(_ref2 => {
    let {
      resolution,
      width,
      height
    } = _ref2;
    const hasWidth = typeof width === 'number' && width > 0;
    const hasHeight = typeof height === 'number' && height > 0;

    if (!hasWidth && !hasHeight && !resolution) {
      throw Error(`${PREFIX} updatePreviewResolution: must be called with a resolution or a custom width/height`);
    }

    let updatedResolution = resolution;

    if (width && height) {
      updatedResolution = {
        id: 'custom',
        label: 'Custom',
        value: [width, height]
      };
      setResolution(updatedResolution);
    } else if (resolution) {
      setResolution(resolution);
    } // Broadcast updated resolution


    if (updatedResolution && onPreviewResolutionChange) {
      onPreviewResolutionChange(updatedResolution);
    }
  }, [setResolution, onPreviewResolutionChange]);

  const updateActivePreviewGroup = group => {
    setActivePreviewGroup(group);
  };

  const adjustZoomLevel = value => {
    setZoomLevel(zoomLevel + value);
  };

  const adjustAspectRatio = ratio => {
    setAspectRatio(ratio);
  };

  return {
    aspectRatio,
    previewResolution,
    updatePreviewResolution,
    activePreviewGroup,
    updateActivePreviewGroup,
    hasDiscoveredResolution: Boolean(previewResolution.value[0] && previewResolution.value[1]),
    zoomLevel,
    adjustZoomLevel,
    adjustAspectRatio
  };
}